////////// BACKGROUND COLOR //////////
.bg-primaryColor {
  background-color: rgba(238, 236, 233, 1);
}
.bg-secondaryColor {
  background-color: rgba(255, 255, 255, 1);
}
.bg-thirdColor {
  background-color: rgba(83, 59, 40, 1);
}
.bg-fourthColor {
  background-color: rgba(242, 129, 47, 1);
}
.bg-fifthColor {
  background-color: rgba(255, 192, 147, 1);
}
.bg-sixthColor {
  background-color: rgb(0, 0, 0);
}
.bg-seventhColor {
  background-color: rgb(0, 0, 0);
}
